<template>
  <v-container class="makePm">
    <v-row>
      <v-col cols="0" xl="1" style="padding:0"></v-col>
      <v-col cols="12" md="6" lg="8" xl="7" style="display:flex;align-items: center;" v-show="!((this.breakpoint === 'xs' || this.breakpoint === 'sm') && this.showPmViewerToggled)">
        <img style="height:60px;margin-top:-10px;margin-bottom:-10px;" :src="require('../assets/makepm/app/ic_launcher_foreground.png')" alt="아이즈원"/>
        <h3>프라이빗 메일 만들기</h3>
      </v-col>
      <v-col col="12" md="6" lg="4" xl="3" style="display:flex;justify-content: center" v-show="this.showPmViewerToggled || this.breakpoint === 'md' || this.breakpoint === 'lg' || this.breakpoint === 'xl'">
        <v-slider style="max-width:420px" color="#DD7898" label="메일 UI 확대/축소" min="0.3" max="1" step="0.025" v-model="viewerScale" @change="changeViewerScale" hide-details></v-slider>
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="0" xl="1" style="padding:0"></v-col>
      <v-col class="list" v-show="showPmList" cols="12" sm="5" md="6" lg="3" xl="3" style="padding-left:0;padding-right:0;">
        <div style="display:flex;align-items:center;flex-direction:column;justify-content: center;min-height:500px;height:100%;text-align:center;line-height:1.8;color:#777;" v-show="Object.keys(pmList).length === 0">
          여기에 멤버들의 메일이 저장됩니다!<br>프라이빗 메일을 작성해 보세요.
          <v-btn color="#DD7898" style="color:white;margin-top:20px; font-weight:bold;" v-show="this.breakpoint ==='xs' || this.breakpoint ==='md'" @click="showPmListToggled = false" rounded><v-icon>mdi-lead-pencil</v-icon>&nbsp;메일 작성</v-btn>
        </div>
        <div style="text-align:center; margin-bottom:20px" v-show="(this.breakpoint === 'xs' || this.breakpoint === 'md') && showPmListToggled && pmList.length !== 0">
          <v-btn color="#DD7898" style="color:white; font-weight:bold;" @click="newPm();showPmListToggled = false" rounded><v-icon>mdi-lead-pencil</v-icon>&nbsp;새 메일 작성</v-btn>
        </div>
        <v-menu v-for="(value,index) in pmList" :key="value.id" transition="slide-y-transition" max-width="120px" rounded="lg" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="pmList-item" v-bind:class="{ pmList_selected: value.id === pmId }" v-on="on" v-bind="attrs">
              <div><img style="height:50px;border-radius:100%;margin-right:10px" :src="require('../assets/makepm/profile/'+value.writer+'.jpg')"/></div>
              <div style="display:flex;flex-direction:column;min-width:0;width:100%">
                <div style="display:flex;">
                  <div style="font-size:.85rem;">{{ pmWriterName(value.writer) }}</div>
                  <div style="font-size:.85rem;color:#bfbfbf;margin-left:auto">
                    <img :src="require('../assets/makepm/app/ic_mail_list_item_clip.png')" alt="" v-show="pmContainsImage(value.content)" style="height:10px;margin-right:2px">
                    {{ pmDateText(value.date) + ' ' + value.time }}
                  </div>
                </div>
                <div style="font-weight:bold;margin-top:4px;display:flex;align-items: start;">
                  <div style="text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">{{ value.title }}&nbsp;</div>
                  <img style="margin-left:auto;height:10px" :src="require('../assets/makepm/app/ic_mail_detail_star.png')" alt="" v-show="value.starred"/>
                </div>
                <div style="color:#555; font-size:.8rem; text-overflow:ellipsis;overflow:hidden;white-space:nowrap;margin-top:4px">{{ stripHTML(value.content) }}</div>
              </div>
            </div>
          </template>
          <v-list dense>
            <v-list-item color="#DD7898" @click="selectPm(index)">
              <v-list-item-title><v-icon>mdi-check</v-icon>&nbsp;&nbsp;&nbsp;선택</v-list-item-title>
            </v-list-item>
            <v-list-item color="#DD7898" @click="deletePm(index)">
              <v-list-item-title><v-icon>mdi-delete</v-icon>&nbsp;&nbsp;&nbsp;삭제</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-show="Object.keys(pmList).length !== 0" style="border-top:1px solid #eee"></div>
      </v-col>
      <v-col class="editor" v-show="showPmEditor" cols="12" sm="7" md="6" lg="5" xl="4">
        <div style="display:flex;flex-direction:column;">
          <div v-show="this.breakpoint === 'xs' || this.breakpoint === 'md'" style="display:flex; justify-content: center;">
            <v-btn color="#DD7898" style="color:white; font-weight:bold; margin-bottom:14px;" @click="showPmListToggled = true; showPmViewerToggled = false;" rounded>
              <v-icon>mdi-email-multiple-outline</v-icon>&nbsp;프라이빗 메일 목록
            </v-btn>
          </div>
          <div style="display:flex; align-items: baseline; margin-bottom:12px">
            <div style="margin-right:10px;">From: </div>
            <v-select color="#DD7898" item-color="primary" v-model="pmWriter" :items="members" style="min-width:100px; max-width:160px" hide-details dense></v-select>
            <div style="margin-left:10px; margin-right:10px">To: </div>
            <v-text-field color="#DD7898" v-model="pmReceiver" placeholder="수신인" hide-details dense></v-text-field>
          </div>
          <div style="display:flex;">
            <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="#DD7898" style="flex-basis:50%; margin-right:15px" :value="pmDateText(pmDate)" label="날짜" hint="YYYY/MM/DD" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" hide-details readonly></v-text-field>
              </template>
              <v-date-picker color="#DD7898" v-model="pmDate" @input="dateMenu = false" :full-width="breakpoint_mini"></v-date-picker>
            </v-menu>
            <v-menu v-model="timeMenu" :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="#DD7898" v-model="pmTime" style="width:50%" label="시간" prepend-icon="mdi-clock-time-four" v-bind="attrs" v-on="on" hide-details readonly></v-text-field>
              </template>
              <v-time-picker color="#DD7898" v-model="pmTime" @click:minute="timeMenu = false" :full-width="breakpoint_mini"></v-time-picker>
            </v-menu>
          </div>
          <div style="display:flex; align-items: center">
            <v-text-field color="#DD7898" style="margin-top:20px;" v-model="pmTitle" placeholder="제목" dense></v-text-field>
            <div class="editor-title-star" style="margin-left:10px" @click="pmStarred = !pmStarred">
              <img :src="require('../assets/makepm/app/ic_mail_detail_star_off.png')" alt="" v-show="!pmStarred"/>
              <img :src="require('../assets/makepm/app/ic_mail_detail_star.png')" alt="" v-show="pmStarred"/>
            </div>
          </div>
          <div style="height:300px">
            <quill-editor style="height:100%" v-model="pmContent" contentType="html" :options="editorOptions"/>
          </div>
          <div style="display:flex; margin-top:70px; justify-content: center; flex-direction:column; align-items: center; width:100%">
            <div style="width:100%; text-align: center">
              <v-btn color="#DD7898" style="color:white; font-weight:bold;margin:6px;" @click="newPm" rounded><v-icon>mdi-lead-pencil</v-icon>&nbsp;새 메일 작성</v-btn>
              <v-btn color="#DD7898" style="color:white; font-weight:bold;margin:6px;" @click="savePm" rounded><v-icon>mdi-content-save-outline</v-icon>&nbsp;메일 저장</v-btn><br>
            </div>
            <div>
              <v-btn v-show="this.breakpoint === 'xs' || this.breakpoint === 'sm'" color="#DD7898" style="color:white; font-weight:bold;margin:6px;" @click="viewPm" rounded>
                <v-icon>mdi-email-outline</v-icon>&nbsp;메일 보기
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>


      <v-col class="viewer-container" v-show="showPmViewer" cols="12" md="6" lg="4" xl="3" style="padding:0;">
        <div class="viewer">
          <div style="height:56px; display:flex;align-items: center; cursor:pointer" @click="showPmViewerToggled = false">
            <img :src="require('../assets/makepm/app/arrow-left.svg')" style="height:26px;margin-left:10px" alt="뒤로가기"/>
          </div>

          <div class="viewer-title-info">
            <img class="viewer-profile" :src="require('../assets/makepm/profile/'+pmWriterImage)" alt="프로필 사진"/>
            <div style="display:flex; flex-direction:column; align-items: start; justify-content: center">
              <span style="font-weight: 600">{{ pmWriterName(pmWriter) }}</span><span style="margin-top:9px; margin-bottom:1px; font-size:.75rem; color:#444">To: {{ pmReceiver }}</span>
            </div>
            <div style="margin-left:auto">
              <img :src="require('../assets/makepm/app/ic_mail_list_item_clip.png')" alt="" v-show="pmContainsImage(pmContent)" style="height:11px;margin-right:6px">
              <span style="color:#bfbfbf">{{ pmDateText(pmDate) }} {{ pmTime }}</span>
            </div>
          </div>
          <div class="viewer-title-text">
            <div class="viewer-title-title">{{ pmTitle }}</div>
            <div style="margin-left:auto;padding-left:4px" @click="pmStarred = !pmStarred">
              <img :src="require('../assets/makepm/app/ic_mail_detail_star_off.png')" alt="" v-show="!pmStarred"/>
              <img :src="require('../assets/makepm/app/ic_mail_detail_star.png')" alt="" v-show="pmStarred"/>
            </div>
          </div>
          <div class="viewer-content" v-html="pmContent"></div>
        </div>
      </v-col>

      <v-col cols="0" xl="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>

import {quillEditor} from 'vue-quill-editor'
import Quill from 'quill'
import {ImageDrop} from 'quill-image-drop-module'
import 'quill/dist/quill.snow.css'
import uniqueString from 'unique-string'
// import { getAnalytics , logEvent } from "firebase/analytics";
// const analytics = getAnalytics();

Quill.register('modules/imageDrop', ImageDrop)

export default {
  name: 'MakePM',
  components: { quillEditor },
  data: () => ({
    editorOptions: {
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'], [{'color':[]}, {'background':[]}], ['image']],
        imageDrop: true
      },
      placeholder: '메일 본문 입력...',
    },


    members:[
      { text: "권은비", value: 1 },
      { text: "미야와키 사쿠라", value: 2 },
      { text: "강혜원", value: 3 },
      { text: "최예나", value: 4 },
      { text: "이채연", value: 5 },
      { text: "김채원", value: 6 },
      { text: "김민주", value: 7 },
      { text: "야부키 나코", value: 8 },
      { text: "혼다 히토미", value: 9 },
      { text: "조유리", value: 10 },
      { text: "안유진", value: 11 },
      { text: "장원영", value: 12 }
    ],

    pmList: [],

    viewerScale: 1,

    pmId: uniqueString(),
    pmTitle: "",
    pmContent: "",
    pmWriter: 1,
    pmReceiver: "위즈원",
    pmStarred: false,
    pmDate:new Date().toLocaleDateString('sv'),
    pmTime:new Date().toLocaleString('sv').substring(11, 16),

    dateMenu: false,
    timeMenu: false,

    breakpoint: '',
    breakpoint_mini: false,
    showPmListToggled: false,
    showPmViewerToggled: false
  }),
  computed: {

    pmWriterImage(){ return this.pmWriter + ".jpg" },


    showPmList(){
      return this.breakpoint === 'xl' || this.breakpoint === 'lg' || (this.breakpoint === 'sm' && !this.showPmViewerToggled) || this.showPmListToggled;
    },
    showPmViewer(){
      return this.breakpoint === 'xl' || this.breakpoint === 'lg' || this.breakpoint === 'md' || this.showPmViewerToggled;
    },
    showPmEditor(){
      if(this.breakpoint === 'xs'){
        return !this.showPmList && !this.showPmViewer;
      }else if(this.breakpoint === 'sm'){
        return !this.showPmViewer;
      }else if(this.breakpoint === 'md'){
        return !this.showPmList;
      }else{
        return true;
      }
    }
  },
  mounted(){
    this.onResize();
    const storage = localStorage.getItem("makepm_list");
    if(!storage){
      this.pmList = [];
    }else{
      this.pmList = JSON.parse(storage);
    }

    window.addEventListener('resize', this.onResize);

    // disable image drag
    const img = document.getElementsByTagName('img');
    for(let i = 0; i < img.length; i++){
      img[i].oncontextmenu = (e) => { e.preventDefault(); return false; }
      img[i].ondragstart = (e) => { e.preventDefault(); return false; }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods:{
    pmContainsImage(content){ return content.includes("<img") },
    pmWriterName(id){
      for(let i = 0; i < this.members.length; i++){
        if(this.members[i].value === id){
          return this.members[i].text;
        }
      }
      return ""
    },
    pmDateText(date){
      return date.replaceAll('-', '/');
    },
    stripHTML(html){
      const tmp = document.implementation.createHTMLDocument("New").body;
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    changeViewerScale(){
      document.getElementsByClassName('viewer')[0].style.transform = "scale(" + this.viewerScale + ")";
    },
    selectPm(index){
      this.showPmListToggled = false;

      const pm = this.pmList[index];
      this.pmId = pm.id;
      this.pmTitle = pm.title;
      this.pmContent = pm.content;
      this.pmWriter = pm.writer;
      this.pmReceiver = pm.receiver;
      this.pmStarred = pm.starred;
      this.pmDate = pm.date;
      this.pmTime = pm.time;
    },
    deletePm(index){
      if(this.pmList[index].id === this.pmId){
        this.newPm();
      }
      this.pmList.splice(index, 1);
      localStorage.setItem("makepm_list", JSON.stringify(this.pmList));

    },
    newPm(){
      this.pmId = uniqueString();
      this.pmTitle = "";
      this.pmContent = "";
      this.pmWriter = 1;
      this.pmReceiver = "위즈원";
      this.pmStarred = false;
      this.pmDate = new Date().toLocaleDateString('sv');
      this.pmTime = new Date().toLocaleString('sv').substring(11, 16);
    },
    savePm(){
      const pm = {
        id: this.pmId,
        title: this.pmTitle,
        content: this.pmContent,
        writer: this.pmWriter,
        receiver: this.pmReceiver,
        starred: this.pmStarred,
        date: this.pmDate,
        time: this.pmTime
      };
      var isNew = true;
      for(let i = 0; i < this.pmList.length; i++){
        if(this.pmList[i].id === pm.id){
          // previously saved pm
          this.pmList[i] = pm;
          isNew = false;
          // add and remove item to update HTML
          this.pmList.push(pm);
          this.pmList.splice(this.pmList.length - 1,1);
        }
      }
      if(isNew){ this.pmList.push(pm) }

      localStorage.setItem("makepm_list", JSON.stringify(this.pmList));
    },

    viewPm(){
      this.showPmViewerToggled = true;
      this.showPmListToggled = false;
    },

    onResize(){
      let width = document.documentElement.clientWidth;

      if(width < 600){
        this.breakpoint = 'xs';
      }else if(width >= 600 && width < 960){
        this.breakpoint = 'sm';
      }else if(width >= 960 && width < 1264){
        this.breakpoint = 'md';
      }else if(width >= 1264 && width < 1904){
        this.breakpoint = 'lg';
      }else if(width >= 1904){
        this.breakpoint = 'xl';
      }

      if(width < 360){
        this.breakpoint_mini = true;
      }else{
        this.breakpoint_mini = false;
      }
    }
  }


}

</script>

<style>

.ql-editor{
  font-size:16px;
}

</style>
<style scoped>

.makePm{
  -webkit-touch-callout: none;
  font-family: Verdana,Roboto,"Droid Sans","游ゴシック",YuGothic,"メイリオ",Meiryo,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","ＭＳ Ｐゴシック",sans-serif;
  font-size:.9rem;
  line-height:1.5;
  height:100%;
  word-wrap:break-word;
}

.pmList_selected{
  background-color: rgba(221,120,152,0.15);
}

.pmList-item{
  display:flex;
  height:90px;
  padding:10px 10px 0 10px;
  border-top:1px solid #eee;
  transition:all 1s;
}


.viewer{
  display:flex;
  flex-direction: column;
  transform-origin:top;
  transition-duration:0.35s;
  transition-timing-function: cubic-bezier(.1,.7,0,1);
}

.viewer-title-info{
  height:90px;
  display:flex;
  border-top:2px solid #eee;
  border-bottom:1px solid #eee;
  padding: 16px 16px 16px 14px;
}

.viewer-profile{
  border-radius:100%;
  height:100%;
  margin-right:18px;
}

.viewer-title-text{
  display:flex;
  align-items: flex-start;
  padding:14px;
  font-size:1.1rem;
  font-weight:bold;
  height:50px;
  border-bottom:1px solid #eee;
}

.viewer-title-title{
  font-family: YuGothic,Verdana,Roboto,"Droid Sans","游ゴシック","メイリオ",Meiryo,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","ＭＳ Ｐゴシック",sans-serif;
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.viewer-title-text img, .editor-title-star img{
  height:23px;
}

.viewer-title-text img:hover, .editor-title-star img:hover{
  cursor:pointer;
}

.viewer-container{
  padding:0;
  display:flex;
  justify-content:center;
}

.viewer{
  width:100%;
  max-width:420px;
}

.viewer-content{
  padding: 24px 14px 14px;
  /*line-height:0.4;*/
}

.viewer-content >>> p{
  margin-bottom: 16px;
}

.viewer-content >>> img{
  max-width:100%;
}

</style>
